<template>
  <div class="container">
    <div class="col col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 mx-auto d-grid gap-5">
      <div class="text-center">
        Waiting for other players...
      </div>
      <UserName/>
    </div>
  </div>
  <Connector ref="connector"/>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { SessionController } from "gamexn/client/session-controller";
import Connector from "../components/Connector.vue";
import UserName from "../components/UserName.vue";

const connector = ref<InstanceType<typeof Connector> | undefined>();
const controller = new SessionController();
const route = useRoute();
const router = useRouter();

onMounted(async () => {
  connector.value?.show();

  const sessionId = route.params["sessionId"] as string;

  controller
      .on("gameStarted", () => router.push({
        name: "game",
        params: { authToken: controller.authToken, handle: controller.handle, sessionId: controller.sessionId },
        replace: true
      }))
      .on("socketConnected", () => connector.value?.hide())
      .on("socketDisconnected", () => connector.value?.show())
      .on("socketReconnected", () => controller.connectToSession(sessionId));

  controller.connectAsync().then(() => controller.connectToSession(sessionId)).catch(() => {
  });
});

onBeforeUnmount(() => controller.disconnect());
</script>
