import "./assets/main.scss";

import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import HomeView from "./views/HomeView.vue";
import InviteView from "./views/InviteView.vue";
import InvitedView from "./views/InvitedView.vue";
import GameView from "./views/GameView.vue";

const app = createApp(App);

app.use(createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            name: "home",
            path: "/",
            component: HomeView
        },
        {
            name: "invite",
            path: "/invite",
            component: InviteView
        },
        {
            name: "invited",
            path: "/invited/:sessionId",
            component: InvitedView
        },
        {
            name: "game",
            path: "/game/:authToken?/:handle?/:sessionId?",
            component: GameView,
        },
        {
            path: "/:pathMatch(.*)*",
            redirect: "/"
        }
    ]
}));

app.mount("#app");
