<template>
  <div class="container">
    <div class="col col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 mx-auto d-grid gap-5">
      <form @submit.prevent="connectToSession">
        <div class="row g-2 align-items-end">
          <div class="col">
            <label for="code" class="form-label">Got invited?</label>
            <input id="code" v-model="sessionId" type="text" class="form-control" placeholder="Enter code" required/>
          </div>
          <div class="col-auto">
            <button type="submit" class="btn btn-primary">Start</button>
          </div>
        </div>
      </form>
      <RouterLink to="/game" class="btn btn-primary">Play solo</RouterLink>
      <RouterLink to="/invite" class="btn btn-primary">Invite a friend</RouterLink>
      <UserName/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import UserName from "../components/UserName.vue";

const sessionId = ref<string>();
const router = useRouter();

function connectToSession() {
  router.push({ name: "invited", params: { sessionId: sessionId.value } });
}
</script>
