import EventEmitter from "eventemitter3";
import type { Game, GameData, GameHost, GameHostEvents, Language } from "gamexn";
import { GameCommand, GameCommands, GameParams } from "./commands/game";
import type { SessionController } from "./session-controller";

export class GameController extends EventEmitter<GameHostEvents> implements GameHost {
    readonly #gameData: GameData;
    readonly #sessionController: SessionController;

    #game?: Game;
    #gameReady = false;
    #inviteReceived?: string;

    #onClose: (message?: string) => void;

    constructor(sessionController: SessionController, gameData: GameData, canvas: HTMLCanvasElement, onClose: (message?: string) => void) {
        super();
        this.#sessionController = sessionController;
        this.#gameData = gameData;
        this.#onClose = onClose;

        sessionController.on("gameDataReceived", this.#onDataReceived.bind(this));
        sessionController.on("userAdded", this.#onUsersAdded.bind(this));
        sessionController.on("userDisconnected", (handle, isHost) => this.emit("userDisconnected", handle, isHost));
        sessionController.on("userRemoved", (handle, isHost) => this.emit("userRemoved", handle, isHost));

        import(/* @vite-ignore */`${gameData.module}${import.meta.env.MODE === "development" ? ".ts" : ".js"}`)
            .then(module => this.#game = new module.default(this, canvas));
    }

    get gameData() {
        return this.#gameData;
    }

    get handle() {
        return this.#sessionController.handle;
    }

    get isHost() {
        return this.#sessionController.isHost;
    }

    get isMultiplayer() {
        return this.#sessionController.players.size > 1;
    }

    get isWeb() {
        return false;
    }

    get language(): Language {
        return "ENU";
    }

    get name() {
        return this.#sessionController.name;
    }

    get players() {
        return this.#sessionController.players;
    }

    get privacyLevel() {
        return 0;
    }

    closeGame(message?: string) {
        console.log(`GameController closeGame ${message}`);

        this.#onClose(message);
    }

    gameReady() {
        console.log(`GameController gameReady authToken: ${this.#sessionController.authToken}, handle: ${this.#sessionController.handle}, sessionId: ${this.#sessionController.sessionId}`);

        this.#gameReady = true;
        this.#sessionController.emit("gameReady");

        if (this.#sessionController.authToken && this.#sessionController.handle && this.#sessionController.sessionId) {
            if (this.isHost)
                this.#game?.startGame();
            else if (this.#inviteReceived)
                this.#sendCommand(new GameCommand(GameCommands.Confirm).with(GameParams.Data, "--"), this.#inviteReceived);
        } else
            this.#game?.startGame();
    }

    gamexnDoCommand(cmd: any, params?: any) {
        console.warn(`gamexnDoCommand not implemented ${cmd} ${params}`);
        // TODO: Not implemented
    }

    onLoadProgress(percentage: number) {
        this.#sessionController.emit("loadProgress", percentage);
    }

    sendData(data: string, address: string = "*") {
        console.log(`GameController sendData ${data} address: ${address}`);

        this.#sendCommand(new GameCommand(GameCommands.GameData)
            /*.with(GameParams.Address, address)*/
            .with(GameParams.Data, data), address);
    }

    startGameSession() {
        console.warn("GameController startGameSession not implemented");
        // TODO: Not implemented
    }

    #onDataReceived(data: string, from: string, address: string) {
        const cmd = GameCommand.parse(data);

        if (!cmd) {
            console.error("invalid data received", data);
            return;
        }

        console.info(`%c<< g ${cmd.toDebugString()} from: ${from} address: ${address}`, "color: Gold");

        switch (cmd.id) {
            case GameCommands.GameData: {
                const data = cmd.getString(GameParams.Data);

                if (data)
                    this.#game?.onGameData(data, from, address);
                break;
            }

            case GameCommands.Invite: {
                this.#inviteReceived = from;

                if (!this.#gameReady)
                    return;

                const id = cmd.getString(GameParams.Id);

                if (!id || id !== this.#sessionController.sessionId)
                    throw Error("invalid command");

                this.#sendCommand(new GameCommand(GameCommands.Confirm).with(GameParams.Data, "--"), from);
                break;
            }

            case GameCommands.Confirm:
                this.#onConfirm();
                break;

            case GameCommands.SetRouter:
                this.#game?.startGame();
                break;

            case GameCommands.CheckHost:
                this.#sendCommand(new GameCommand(GameCommands.Invite), from);
                break;

            case GameCommands.CloseGame:
                this.closeGame("Other party has closed the game.");
                break;

            default:
                console.warn("command not implemented", GameCommands[cmd.id]);
                break;
        }
    }

    #onConfirm() {
        const handles = [...this.players.keys()].map(handle => `a${handle}`).join(",");

        const pi = [...this.players.values()].map((displayName, index) => index === 0
            ? `0@${displayName}`
            : `0.${index}@${displayName}:0:0.${index}`).join(";");

        this.#sendCommand(new GameCommand(GameCommands.SetRouter)
            .with(GameParams.Ready, true)
            .with(GameParams.HandleList, handles)
            .with(GameParams.PiList, pi));

        this.#sessionController.setGameUsers();
        this.#game?.startGame();
    }

    #onUsersAdded(handle: string) {
        if (this.#sessionController.isHost)
            this.#sendCommand(new GameCommand(GameCommands.Invite), handle);
        else
            this.#sendCommand(new GameCommand(GameCommands.CheckHost), handle);
    }

    #sendCommand(cmd: GameCommand, to: string = "*") {
        cmd = cmd.with(GameParams.Id, this.#sessionController.sessionId!);

        console.log(`%c>> g ${cmd.toDebugString()} to: ${to}`, "color: HotPink");

        this.#sessionController.sendGameData(cmd.toString(), to);
    }
}
