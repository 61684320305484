<template>
  <div ref="loader">
    <div class="mb-1 text-light text-center">
      Loading...
    </div>
    <div class="progress w-50 mx-auto">
      <div ref="progress" class="progress-bar progress-bar-striped" role="progressbar"></div>
    </div>
  </div>
  <canvas ref="canvas" class="d-none"></canvas>
  <div ref="modalEl" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div ref="modalBody" class="modal-body"></div>
        <div class="modal-footer justify-content-center">
          <RouterLink to="/" class="btn btn-primary btn-sm">OK</RouterLink>
        </div>
      </div>
    </div>
  </div>
  <Connector ref="connector"/>
</template>

<script setup lang="ts">
import Modal from "bootstrap/js/dist/modal";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import type { GameData } from "gamexn";
import { ErrorCodes } from "gamexn/client/commands/protocol";
import { SessionController } from "gamexn/client/session-controller";
import Games from "../../games.json";
import Connector from "../components/Connector.vue";

const canvas = ref<HTMLCanvasElement>();
const connector = ref<InstanceType<typeof Connector> | undefined>();
const controller = new SessionController();
const gameData = Games[0] as GameData;
const loader = ref<HTMLElement>();
const modalEl = ref<HTMLElement>();
const modalBody = ref<HTMLElement>();
const progress = ref<HTMLElement>();
const route = useRoute();
const router = useRouter();

let modalMessage: Modal | undefined;

onMounted(async () => {
  onResize();
  window.addEventListener("resize", onResize);

  const authToken = route.params["authToken"] as string | undefined;
  const handle = route.params["handle"] as string | undefined;
  const sessionId = route.params["sessionId"] as string | undefined;

  controller
      .on("connectedToGame", () => controller.createGameController(gameData, canvas.value!, close))
      .on("gameReady", () => {
        loader.value?.classList.add("d-none");
        canvas.value?.classList.replace("d-none", "d-block");
      })
      .on("loadProgress", percentage => {
        if (progress.value)
          progress.value.style.width = `${Math.round(percentage)}%`;
      })
      .on("errorReceived", error => close(`Error: ${ErrorCodes[error]}`))
      .on("socketConnected", () => connector.value?.hide())
      .on("socketDisconnected", ev => {
        if (ev === undefined || !ev.wasClean)
          connector.value?.show();
      })
      .on("socketReconnected", () => {
        console.log("socket reconnected");
        controller.reconnectToGame();
      })
      .on("userDisconnected", () => connector.value?.show())
      .on("userReconnected", () => connector.value?.hide());

  if (!canvas.value)
    return;

  if (authToken && handle && sessionId)
    controller.connectAsync().then(() => controller.connectToGame(authToken, handle, sessionId)).catch(() => {
    });
  else
    controller.createGameController(gameData, canvas.value!, close);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize);
  modalMessage?.dispose();
  controller.disconnect();
});

function close(message?: string) {
  controller.disconnect();

  if (message && modalEl.value && modalBody.value) {
    modalBody.value.textContent = message;

    modalMessage = new Modal(modalEl.value, {
      backdrop: "static",
      keyboard: false
    });
    modalMessage.show();
  } else
    router.push({ path: "/", replace: true });
}

function onResize() {
  if (!canvas.value)
    return;

  canvas.value.width = document.documentElement.clientWidth;
  canvas.value.height = document.documentElement.clientHeight;
  canvas.value.dispatchEvent(new Event("resize"));
}
</script>

<style scoped>
canvas {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
