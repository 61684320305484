import { Platforms, ProtocolVersion } from "./protocol";

export class GameCommand {
    static readonly #paramSeparator = ":";

    readonly id: GameCommands;
    readonly params = new Map<GameParams, string>();

    constructor(id: GameCommands) {
        this.id = id;
    }

    static parse(data: string): GameCommand | undefined {
        const array = data.split(GameCommand.#paramSeparator, 4);

        if (array.length !== 4)
            return;

        const version = parseInt(array[0]!);

        if (version < 0 || version > ProtocolVersion)
            return;

        const cmd = new GameCommand(parseInt(array[2]!));
        const paramsCount = parseInt(array[3]!);

        if (paramsCount === 0)
            return cmd;

        let offset = 0, length = 0;

        for (let i = 0; i < 4; i++) {
            length = data.indexOf(GameCommand.#paramSeparator, offset);
            offset += length - offset + 1;
        }

        while (cmd.params.size < paramsCount) {
            length = data.indexOf(GameCommand.#paramSeparator, offset);
            const paramId = data.substring(offset, length);
            offset += length - offset + 1;

            length = data.indexOf(GameCommand.#paramSeparator, offset);
            const paramValueLength = parseInt(data.substring(offset, length));
            offset += length - offset + 1;

            const paramValue = data.substring(offset, offset + paramValueLength);
            offset += paramValueLength + 1;

            cmd.params.set(parseInt(paramId), paramValue);
        }

        return cmd;
    }

    getString(id: GameParams): string | undefined {
        return this.params.get(id);
    }

    toDebugString() {
        if (!this.params.size)
            return `${GameCommands[this.id]}`;

        return `${GameCommands[this.id]}: { ${[...this.params.entries()]
            .map(([id, value]) => `${GameParams[id]}: "${value}"`).join(", ")} }`;
    }

    toString() {
        return [
            ...[ProtocolVersion.toString(), Platforms.Windows.toString(), this.id.toString(), this.params.size.toString()],
            ...[...this.params.entries()].map(([id, value]) => [id, value.length, value].join(GameCommand.#paramSeparator))
        ].join(GameCommand.#paramSeparator);
    }

    with(id: GameParams, value: boolean | number | string /*| IterableIterator<string>*/) {
        switch (typeof value) {
            case "boolean":
                this.params.set(id, value ? "1" : "0");
                break;

            case "number":
                this.params.set(id, value.toString());
                break;

            case "string":
                this.params.set(id, value);
                break;

            /*
            case "object":
                this.params.set(id, this.#listSeparator + [...value].join(this.#listSeparator));
                break;
            */

            default:
                throw new TypeError("Unsupported value type");
        }

        return this;
    }
}

export enum GameCommands {
    None,
    GameData,
    Invite,
    InvitePlayer,
    InviteObserver,
    Confirm,
    Refuse,
    Reject,
    SetRouter,
    CheckHost,
    NewGame,
    CloseGame,
    GetPI,
    SetPI,
    ApplyReplica,
    AddPlayer,
    AddObserver,
    RemovePlayer,
    GameRemovePlayer,
    Shake,
    WaitConnection,
    ResumeBuddy,
    ResumeGame,
    Ping,
    Pong,
    Finger,
    CreateTournament,
    GameAppData,
    InviteLobbyFriend
}

export enum GameParams {
    None,
    Command,
    Id,
    Name,
    PiList,
    Server,
    Local,
    Handle,
    Ready,
    Data,
    Address,
    HandleList,
    Color,
    X,
    Y,
    On,
    UDP,
    Tournament,
    ClientVersion
}
