<template>
  <div>
    <hr class="opacity-50"/>
    <form @submit.prevent="apply">
      <div class="row g-2 align-items-end">
        <div class="col">
          <label for="name" class="form-label">Friend see you as:</label>
          <input id="name" v-model="name" type="text" class="form-control" placeholder="Anonymous" autocomplete="name"/>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-primary">Apply</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const name = ref(localStorage.getItem(nameStorageKey));

function apply() {
  const s = name.value?.trim();
  if (s)
    localStorage.setItem(nameStorageKey, s);
  else
    localStorage.removeItem(nameStorageKey);
}
</script>
