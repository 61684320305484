<template>
  <div ref="el" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div ref="modal-body" class="modal-body d-flex align-items-center justify-content-center">
          <div class="spinner-border me-2" role="status"></div>
          <div>Connecting...</div>
        </div>
        <div class="modal-footer justify-content-center">
          <RouterLink to="/" class="btn btn-primary">Cancel</RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Modal from "bootstrap/js/dist/modal";
import { onBeforeUnmount, onMounted, ref } from "vue";

const el = ref<Element>();

let modal: Modal;

defineExpose({
  show: () => modal.show(),
  hide: () => modal.hide()
});

onMounted(() => {
  if (el.value)
    modal = new Modal(el.value, { backdrop: "static", keyboard: false });
});

onBeforeUnmount(() => modal.dispose());
</script>
