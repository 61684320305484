﻿import { ProtocolVersion } from "./protocol";

export class SessionCommand {
    readonly id: SessionCommands;
    readonly params = new Map<SessionParams, string>();

    readonly #listSeparator = "|";

    constructor(id: SessionCommands) {
        this.id = id;
    }

    static parse(data: Uint8Array): SessionCommand | undefined {
        let offset = 0;
        const reader = new DataView(data);

        const commandLength = reader.getUint16(offset, true) + 2;
        offset += 2;

        if (data.byteLength !== commandLength) {
            console.error(`invalid command length. ${data.byteLength} instead of ${commandLength}`);
            return;
        }

        const version = reader.getUint8(offset++);

        if (version < 0 || version > ProtocolVersion)
            return;

        const cmd = new SessionCommand(reader.getUint8(offset++));
        const paramsCount = reader.getUint8(offset++);

        if (paramsCount === 0)
            return cmd;

        const textDecoder = new TextDecoder();

        for (let i = 0; i < paramsCount; i++) {
            const paramId = reader.getUint8(offset++);

            const paramLength = reader.getUint16(offset, true);
            offset += 2;

            cmd.params.set(paramId, textDecoder.decode(reader.buffer.slice(offset, offset + paramLength)));
            offset += paramLength;
        }

        return cmd;
    }

    getArray(id: SessionParams): string[] | undefined {
        const s = this.params.get(id);

        if (s === undefined)
            return undefined;

        if (s.length === 0)
            return [];

        return s.substring(1).split(this.#listSeparator);
    }

    getBoolean(id: SessionParams): boolean | undefined {
        const s = this.params.get(id);
        return s ? s !== "0" : undefined;
    }

    getNumber(id: SessionParams): number | undefined {
        const s = this.params.get(id);
        return s ? Number(s) : undefined;
    }

    getString(id: SessionParams): string | undefined {
        return this.params.get(id);
    }

    toArray(): Uint8Array {
        const encoder = new TextEncoder();
        const params = new Set<BinaryData>();

        let dataLength = 0;

        for (const [id, value] of this.params.entries()) {
            const bValue = encoder.encode(value);

            const pArray = new Uint8Array(bValue.byteLength + 3);
            const pWriter = new DataView(pArray.buffer);
            pWriter.setUint8(0, id);
            pWriter.setUint16(1, bValue.byteLength, true);
            pArray.set(bValue, 3);

            dataLength += pArray.byteLength;
            params.add(pArray);
        }

        let offset = 5;

        const cArray = new Uint8Array(dataLength + offset);
        const cWriter = new DataView(cArray.buffer);
        cWriter.setUint16(0, dataLength + offset - 2, true);
        cWriter.setUint8(2, ProtocolVersion);
        cWriter.setUint8(3, this.id);
        cWriter.setUint8(4, this.params.size);

        for (const param of params.values()) {
            cArray.set(param as any as ArrayLike<number>, offset);
            offset += (param as any as BinaryData).byteLength;
        }

        return cArray;
    }

    toDebugString(): string {
        if (!this.params.size)
            return `${SessionCommands[this.id]}`;

        const params = [...this.params.entries()]
            .map<string>(([id, value]) => `${SessionParams[id]}: "${value}"`).join(", ");

        return `${SessionCommands[this.id]}: { ${params} }`;
    }

    with(id: SessionParams, value?: boolean | number | string | Iterable<string>): SessionCommand {
        if (typeof value === undefined)
            return this;

        switch (typeof value) {
            case "boolean":
                this.params.set(id, value ? "1" : "0");
                break;

            case "number":
                this.params.set(id, value.toString());
                break;

            case "string":
                this.params.set(id, value);
                break;

            case "object":
                this.params.set(id, this.#listSeparator + [...value].join(this.#listSeparator));
                break;

            default:
                throw new TypeError(`Unsupported value type: ${typeof value}`);
        }

        return this;
    }
}

export enum SessionCommands {
    SetServerType,
    CreateSession,
    CreateSessionWithId,
    Logon,
    Logoff,
    ConnectToSession,
    SessionCreated,
    Invite,
    AddUser,
    DeleteUser,
    RefuseInvite,
    StartGame,
    ConnectToGame,
    ReconnectToGame,
    ConnectionRestored,
    GameData,
    Error,
    Ping,
    GetOnlineUsers,
    SetOnlineUsers,
    SessionExpire,
    LoginSuccess,
    LoginNotSuccess,
    ConnectedToSession,
    ConnectedToGame,
    ConfirmSession,
    SessionSettings,
    DeclineSession,
    Pong,
    CancelInvite,
    DisconnectSession,
    ReconnectLogon,
    HasOtherInstance,
    HasNotOtherInstance,
    ConnectByInvite,
    CreateLobbySession,
    UpdateUser,
    LobbySessionCreated,
    ChangeLobbyHost,
    WaitDownload,
    CheckFriendsOnline,
    SetFriendsOnline,
    SessionAccepted,
    AddUserToGame,
    SetGameUsers,
    ConfirmReceived,
    UserDisconnected,
    UserReconnected,
    LobbyLogon,
    LobbyTablesCount,
    LobbySuccess,
    LobbyNotSuccess,
    GetLobbyTables,
    SetLobbyTables,
    NotifyLobbyTable,
    SelectLobbyTable,
    UnselectLobbyTable,
    CreateLobbyTable,
    DeleteLobbyTable,
    TurnOnLobbyNotify,
    TurnOffLobbyNotify,
    BlackList,
    GetFriendTables,
    SetFriendTables,
    ChangeOnlineDsp,
    UpdateTables,
    GoOffline
}

export enum SessionParams {
    ServerType,
    GameId,
    Name,
    Handle,
    HandleList,
    DisplayNameList,
    SessionId,
    MaxParticipants,
    MinParticipants,
    IsHost,
    Ip,
    Port,
    ErrCode,
    ErrString,
    Redirect,
    Language,
    From,
    To,
    Address,
    HostHandle,
    Data,
    Count,
    Cookie,
    GameName,
    AnyOne,
    Profile,
    LogonSessionId,
    StayOnline,
    VersionInfo,
    UpdateCritical,
    HasLobby,
    PrivacyLevel,
    MultiCast,
    AuthToken,
    ClientVersion,
    MsgSendNo,
    MsgReceiveNo,
    Level,
    TablesList,
    GamesList,
    Lobby,
    GameIndex,
    Udp,
    Country
}
