export const ProtocolVersion = 1;

export const enum Platforms {
    Unknown = " ",
    Windows = "w"
}

export enum ErrorCodes {
    Unknown,
    ItemNotFound,
    SetupNotCompleted,
    UserNotAuthorized,
    ClientVersionIncompatible,
    UserDoNotDisturbStatus,
    ItemVersionIncompatible,
    Unknown7,
    UserClosedInvitation,
    ClientClosed,
    InvalidSessionId,
    ConnectionTerminated,
    SessionFull,
    WrongData,
    GameClosed,
    UserRefuse,
    UserLogoff,
    SessionClosed,
    GameNotExists,
    DisconnectSession,
    SessionExists,
    ClientAlreadyConnected,
    WrongAddress,
    WrongCommand
}

export enum ServerTypes {
    Online,
    Game,
    Lobby
}
