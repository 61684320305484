﻿import EventEmitter from "eventemitter3";
import type { GameData } from "gamexn";
import { ErrorCodes, ServerTypes } from "./commands/protocol";
import { SessionCommand, SessionCommands, SessionParams } from "./commands/session";
import { GameController } from "./game-controller";
import * as Utils from "./utils";

export class SessionController extends EventEmitter<SessionControllerEvents> {
    readonly players = new Map<string, string>;

    readonly #clientVersion = 1;
    readonly #cookie = Math.random().toString().substring(2);
    readonly #reconnectInterval = 2000;
    readonly #sendQueue = new Map<number, SessionCommand>();
    // readonly #storageKeySession = "gxn_session";

    #authToken?: string;
    // @ts-ignore
    #gameController?: GameController;
    #handle?: string;
    #hostHandle?: string;
    #msgSendNo = 0;
    #msgReceiveNo = 0;
    #pingInterval = 4000;
    #pingTimer = 0;
    #pingTimerTicks = 0;
    #pongReceived = true;
    #sessionId?: string;
    #socket?: WebSocket;

    constructor() {
        super();
    }

    get authToken() {
        return this.#authToken;
    }

    get handle() {
        return this.#handle;
    }

    get isHost() {
        return this.#handle === undefined || this.#handle === this.#hostHandle;
    }

    get name(): string {
        return localStorage.getItem(nameStorageKey) ?? "Anonymous";
    }

    get sessionId() {
        return this.#sessionId;
    }

    connectAsync(): Promise<SessionController> {
        console.log("SessionController connectAsync");

        if (this.#socket && this.#socket.readyState !== WebSocket.CLOSED) {
            console.warn("socket.readyState != closed");
            return Promise.reject();
        }

        return new Promise<SessionController>((resolve, reject) => {
            this.#socket = new WebSocket(socketUrl, "gamexn");
            this.#socket.binaryType = "arraybuffer";

            this.#socket.addEventListener("open", () => {
                this.#onSocketOpen();
                resolve(this);
            });

            this.#socket.addEventListener("close", this.#onSocketClose.bind(this));

            this.#socket.addEventListener("error", () => {
                reject();
                this.#onSocketError();
            });

            this.#socket.addEventListener("message", this.#onSocketMessage.bind(this));
        });
    }

    connectToGame(authToken: string, handle: string, sessionId: string) {
        console.log(`SessionController connectToGame authToken: ${authToken}, handle: ${handle}, sessionId: ${sessionId}`);

        this.#authToken = authToken;
        this.#handle = handle;
        this.#sessionId = sessionId;

        this.players.set(handle, this.name);

        this.#sendCommand(new SessionCommand(SessionCommands.ConnectToGame).with(SessionParams.Handle, this.#handle)
            .with(SessionParams.SessionId, this.#sessionId)
            .with(SessionParams.StayOnline, false)
            .with(SessionParams.ClientVersion, this.#clientVersion));
    }

    connectToSession(sessionId: string) {
        console.log(`SessionController connectToSession ${sessionId}`);

        this.#sessionId = sessionId;

        this.#sendCommand(new SessionCommand(SessionCommands.ConnectToSession)
            .with(SessionParams.SessionId, sessionId)
            .with(SessionParams.Cookie, this.#cookie)
            .with(SessionParams.Name, this.name)
            .with(SessionParams.ClientVersion, this.#clientVersion));
    }

    createGameController(gameData: GameData, canvas: HTMLCanvasElement, onClose: (message?: string) => void) {
        console.log("SessionController createGameController %o", gameData);

        this.#gameController = new GameController(this, gameData, canvas, message => {
            onClose.call(this, message);
            this.disconnect();
        });
    }

    createSession(gameData: GameData) {
        console.log("SessionController createSession", gameData);

        const cmd = new SessionCommand(SessionCommands.CreateSession)
            .with(SessionParams.GameId, gameData.id)
            .with(SessionParams.GameName, gameData.name)
            //.with(SessionParams.Handle, this.#handle)
            .with(SessionParams.Cookie, this.#cookie)
            .with(SessionParams.Name, this.name)
            .with(SessionParams.AnyOne, 1)
            .with(SessionParams.VersionInfo, 0)
            .with(SessionParams.PrivacyLevel, 1)
            .with(SessionParams.ClientVersion, this.#clientVersion);

        if (gameData.minParticipants)
            cmd.with(SessionParams.MinParticipants, gameData.minParticipants);

        if (gameData.maxParticipants)
            cmd.with(SessionParams.MaxParticipants, gameData.maxParticipants);

        this.#sendCommand(cmd);
    }

    disconnect() {
        if (!this.#socket || this.#socket.readyState === WebSocket.CLOSING || this.#socket.readyState === WebSocket.CLOSED)
            return;

        console.log(`SessionController disconnect from ${SocketStates[this.#socket.readyState]}`);

        if (this.#socket.readyState === WebSocket.OPEN) {
            this.#sendCommand(new SessionCommand(SessionCommands.Logoff));

            this.#authToken = undefined;
            this.#handle = undefined;
            this.#sessionId = undefined;
        }

        clearInterval(this.#pingTimer);

        this.#socket.close();
    }

    reconnectToGame() {
        console.log("SessionController reconnectToGame");

        this.#sendCommand(new SessionCommand(SessionCommands.ReconnectToGame)
            .with(SessionParams.AuthToken, this.#authToken)
            .with(SessionParams.Handle, this.#handle)
            .with(SessionParams.SessionId, this.#sessionId)
            .with(SessionParams.ClientVersion, this.#clientVersion)
            .with(SessionParams.MsgReceiveNo, this.#msgReceiveNo));
    }

    sendGameData(data: string, address: string = "*") {
        if (!this.#handle)
            return;

        this.#sendCommand(new SessionCommand(SessionCommands.GameData)
            .with(SessionParams.From, this.#handle)
            .with(SessionParams.Address, `|${address}`)
            .with(SessionParams.Data, data)
            .with(SessionParams.MsgSendNo, ++this.#msgSendNo));
    }

    setGameUsers() {
        console.log("SessionController setGameUsers");

        this.#sendCommand(new SessionCommand(SessionCommands.SetGameUsers).with(SessionParams.HandleList, this.players.keys()));
    }

    startGame() {
        console.log("SessionController startGame");

        this.#sendCommand(new SessionCommand(SessionCommands.StartGame));
    }

    #onAddUser(handle: string, displayName: string) {
        this.players.set(handle, displayName);
        this.emit("userAdded", handle, displayName);
    }

    #onConnectedToSession(gameId: string, handle: string) {
        this.#handle = handle;
        this.players.set(handle, this.name);
        this.emit("connectedToSession", gameId);
    }

    #onConnectedToGame(hostHandle: string, playersCount: number, players?: Map<string, string>) {
        this.#hostHandle = hostHandle;

        players?.forEach((displayName, handle) => this.players.set(handle, displayName));

        this.emit("connectedToGame", hostHandle, playersCount);
    }

    #onConnectionRestored(msgReceiveNo: number) {
        console.log(`SessionController onConnectionRestored: ${msgReceiveNo} of ${this.#msgSendNo}`);
        console.log(`handle: ${this.#handle}, hostHandle: ${this.#hostHandle}, sessionId: ${this.#sessionId}, authToken: ${this.#authToken}, players: ${this.players.size}`);

        if (msgReceiveNo >= this.#msgSendNo)
            return;

        for (const [msgNo, cmd] of this.#sendQueue.entries()) {
            if (msgNo > msgReceiveNo)
                this.#sendCommand(cmd);
        }
    }

    #onDeleteUser(handle: string, isHost: boolean) {
        if (this.players.delete(handle))
            this.emit("userRemoved", handle, isHost);
    }

    #onOffline() {
        console.warn("SessionController onOffline");

        window.removeEventListener("offline", this.#onOffline.bind(this));

        if (!this.#socket || this.#socket.readyState === WebSocket.CLOSING || this.#socket.readyState === WebSocket.CLOSED)
            return;

        clearInterval(this.#pingTimer);

        console.log(`offline disconnect ${SocketStates[this.#socket.readyState]}`);
        this.#socket.close(4000, "offline");
    }

    #onSessionCreated(sessionId: string, handle: string) {
        this.#handle = handle;
        this.#hostHandle = handle;
        this.#sessionId = sessionId;
        this.players.set(handle, this.name);
        this.emit("sessionCreated", sessionId);
    }

    #onSessionSettings(hostHandle: string, players: Map<string, string>) {
        this.#hostHandle = hostHandle;
        this.emit("sessionSettings", hostHandle);

        players.forEach((displayName, handle) => {
            this.players.set(handle, displayName);
            this.emit("userAdded", handle, displayName);
        });
    }

    #onSocketClose(ev: CloseEvent) {
        console.log(`%csocket close, wasClean: ${ev.wasClean}, ${ev.code} ${ev.reason}`, "color: Red");

        clearInterval(this.#pingTimer);
        //this.#socket = undefined;

        if (ev.wasClean) {
            this.players.clear();
            this.#hostHandle = undefined;
        } else {
            // this.#saveSession();

            console.log(`reconnect in ${this.#reconnectInterval}`);
            setTimeout(this.#onSocketReconnect.bind(this), this.#reconnectInterval);
        }

        this.emit("socketDisconnected", ev);
    }

    #onSocketError() {
        console.log("%csocket error", "color: Red");
    }

    #onSocketOpen() {
        console.log("%csocket open", "color: Red");

        window.addEventListener("offline", this.#onOffline.bind(this));

        console.log(`pingTimer set ${this.#pingInterval}`);
        this.#pingTimer = setInterval(this.#pingTimerTick.bind(this), this.#pingInterval);
        this.#pingTimerTicks = 0;

        this.#sendCommand(new SessionCommand(SessionCommands.SetServerType).with(SessionParams.ServerType, ServerTypes.Game));
        this.emit("socketConnected");
    }

    #onSocketMessage(ev: MessageEvent<Uint8Array>) {
        if (ev.data.byteLength < 1)
            console.error(`${ev.data.byteLength} received`);

        const cmd = SessionCommand.parse(ev.data);

        if (!cmd) {
            console.error("invalid data received", Utils.binToHex(ev.data));
            return;
        }

        if (cmd.id !== SessionCommands.Ping && cmd.id !== SessionCommands.Pong)
            console.log(`%c<< s ${SocketStates[this.#socket?.readyState ?? WebSocket.CLOSED]} ${cmd.toDebugString()}`, "color: DodgerBlue");

        switch (cmd.id) {
            case SessionCommands.SessionCreated: {
                const sessionId = cmd.getString(SessionParams.SessionId);
                const handle = cmd.getString(SessionParams.Handle);

                if (!sessionId || !handle)
                    throw new Error("invalid command");

                this.#onSessionCreated(sessionId, handle);
                break;
            }

            case SessionCommands.AddUser: {
                const handle = cmd.getString(SessionParams.Handle);
                const displayName = cmd.getString(SessionParams.Name);

                if (!handle || !displayName)
                    throw new Error("invalid command");

                this.#onAddUser(handle, displayName);
                break;
            }

            case SessionCommands.DeleteUser: {
                const handle = cmd.getString(SessionParams.Handle);
                const isHost = cmd.getBoolean(SessionParams.IsHost);

                if (!handle || isHost === undefined)
                    throw new Error("invalid command");

                this.#onDeleteUser(handle, isHost);
                break;
            }

            case SessionCommands.StartGame: {
                const authToken = cmd.getString(SessionParams.AuthToken);

                if (!authToken)
                    throw new Error("invalid command");

                this.#authToken = authToken;
                this.emit("gameStarted");
                break;
            }

            case SessionCommands.ConnectionRestored: {
                const msgReceiveNo = cmd.getNumber(SessionParams.MsgReceiveNo);

                if (msgReceiveNo === undefined)
                    throw new Error("invalid command");

                this.#onConnectionRestored(msgReceiveNo);
                break;
            }

            case SessionCommands.GameData: {
                const data = cmd.getString(SessionParams.Data);
                const from = cmd.getString(SessionParams.From);
                const address = cmd.getString(SessionParams.Address);

                if (!data || !from || !address)
                    throw new Error("invalid command");

                this.emit("gameDataReceived", data, from, address);

                const msgNo = cmd.getNumber(SessionParams.MsgSendNo);

                if (msgNo !== undefined) {
                    this.#msgReceiveNo = msgNo;
                    this.#sendCommand(new SessionCommand(SessionCommands.ConfirmReceived).with(SessionParams.MsgReceiveNo, msgNo));
                }
                break;
            }

            case SessionCommands.Error:
                const errorCode = cmd.getNumber(SessionParams.ErrCode) ?? 0;
                console.error("error received:", ErrorCodes[errorCode]);
                this.emit("errorReceived", errorCode);
                break;

            case SessionCommands.Ping: {
                const data = cmd.getNumber(SessionParams.Data);

                if (data && data !== this.#pingInterval) {
                    this.#pingInterval = data;

                    clearInterval(this.#pingTimer);
                    console.log("pingTimer clear");

                    this.#pingTimer = setInterval(this.#pingTimerTick.bind(this), data);
                    console.log(`pingTimer set ${data}`);
                }

                const cookie = cmd.getString(SessionParams.Cookie);

                if (cookie)
                    this.#sendCommand(new SessionCommand(SessionCommands.Pong).with(SessionParams.Cookie, cookie));
                else
                    this.#sendCommand(new SessionCommand(SessionCommands.Pong));
                break;
            }

            case SessionCommands.ConnectedToSession: {
                const gameId = cmd.getString(SessionParams.GameId);
                const handle = cmd.getString(SessionParams.Handle);

                if (!gameId || !handle)
                    throw new Error("invalid command");

                this.#onConnectedToSession(gameId, handle);
                break;
            }

            case SessionCommands.ConnectedToGame: {
                const hostHandle = cmd.getString(SessionParams.HostHandle);
                const playersCount = cmd.getNumber(SessionParams.Count);

                if (!hostHandle || playersCount === undefined)
                    throw new Error("invalid command");

                const handleList = cmd.getArray(SessionParams.HandleList);
                const displayNameList = cmd.getArray(SessionParams.DisplayNameList);

                if (handleList && displayNameList)
                    this.#onConnectedToGame(hostHandle, playersCount,
                        new Map(handleList.map((handle, index) => [handle, displayNameList[index]!])));
                else
                    this.#onConnectedToGame(hostHandle, playersCount);
                break;
            }

            case SessionCommands.SessionSettings: {
                const hostHandle = cmd.getString(SessionParams.HostHandle);
                const handleList = cmd.getArray(SessionParams.HandleList);
                const displayNameList = cmd.getArray(SessionParams.DisplayNameList);

                if (!hostHandle || !handleList || !displayNameList)
                    throw new Error("invalid command");

                this.#onSessionSettings(hostHandle,
                    new Map(handleList.map((handle, index) => [handle, displayNameList[index]!])));
                break;
            }

            case SessionCommands.Pong:
                this.#pongReceived = true;
                break;

            case SessionCommands.UserDisconnected: {
                const handle = cmd.getString(SessionParams.Handle);
                const isHost = cmd.getBoolean(SessionParams.IsHost);

                if (!handle || isHost === undefined)
                    throw new Error("invalid command");

                this.#onUserDisconnected(handle, isHost);
                break;
            }

            case SessionCommands.UserReconnected: {
                const handle = cmd.getString(SessionParams.Handle);
                const displayName = cmd.getString(SessionParams.Name);

                if (!handle || !displayName)
                    throw new Error("invalid command");

                this.#onUserReconnected(handle, displayName);
                break;
            }

            default:
                console.warn("command not implemented", SessionCommands[cmd.id]);
                break;
        }
    }

    #onSocketReconnect() {
        this.connectAsync().then(() => this.emit("socketReconnected")).catch(() => {
        });
    }

    #onUserReconnected(handle: string, displayName: string) {
        this.emit("userReconnected", handle, displayName);
    }

    #onUserDisconnected(handle: string, isHost: boolean) {
        this.emit("userDisconnected", handle, isHost);
    }

    #pingTimerTick() {
        if (this.#pongReceived) {
            this.#pingTimerTicks = 0;
            this.#pongReceived = false;
            this.#sendCommand(new SessionCommand(SessionCommands.Ping));
        } else if (++this.#pingTimerTicks < 2)
            this.#sendCommand(new SessionCommand(SessionCommands.Ping));
        else {
            clearInterval(this.#pingTimer);
            console.log("pingTimer disconnect");
            this.emit("socketDisconnected");
            this.#socket?.close(4001, "ping timer");

            // this.#onSocketClose(new CloseEvent("close", { wasClean: false, code: 4001, reason: "ping timer" }));
        }
    }

    /*
        #saveSession() {
            console.log("SessionController #saveSession");

            const session: StorageItemSession = {
                authToken: this.#authToken!,
                handle: this.#handle!,
                msgReceiveNo: this.#msgReceiveNo,
                sessionId: this.#sessionId!
            };

            sessionStorage.setItem(this.#storageKeySession, JSON.stringify(session));
        }
    */

    #sendCommand(cmd: SessionCommand) {
        if (this.#socket?.readyState !== WebSocket.OPEN) {
            console.warn(`invalid socket state: ${this.#socket?.readyState} >> ${cmd.toDebugString()}`);
            return;
        }

        if (cmd.id !== SessionCommands.Ping && cmd.id !== SessionCommands.Pong)
            console.log(`%c>> s ${SocketStates[this.#socket.readyState]} ${cmd.toDebugString()}`, "color: OliveDrab");

        if (cmd.id === SessionCommands.GameData && !this.#sendQueue.has(this.#msgSendNo))
            this.#sendQueue.set(this.#msgSendNo, cmd);

        this.#socket.send(cmd.toArray());
    }
}

interface SessionControllerEvents {
    connectedToGame: (hostHandle: string, playersCount: number) => void;
    connectedToSession: (gameId: string) => void;
    errorReceived: (error: number) => void;
    gameDataReceived: (data: string, from: string, address: string) => void;
    gameReady: () => void;
    gameStarted: () => void;
    loadProgress: (percentage: number) => void;
    sessionCreated: (sessionId: string) => void;
    sessionSettings: (hostHandle: string) => void;
    socketConnected: () => void;
    socketDisconnected: (ev?: CloseEvent) => void;
    socketReconnected: () => void,
    userAdded: (handle: string, displayName: string) => void;
    userDisconnected: (handle: string, isHost: boolean) => void;
    userReconnected: (handle: string, displayName: string) => void;
    userRemoved: (handle: string, isHost: boolean) => void;
}

/*
type StorageItemSession = {
    authToken: string;
    handle: string;
    msgReceiveNo: number;
    sessionId: string;
}
*/

enum SocketStates {
    connecting,
    open,
    closing,
    closed
}
