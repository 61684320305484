<template>
  <div class="container">
    <div class="col col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 mx-auto d-grid gap-5">
      <form>
        <div class="row g-2 align-items-end">
          <div class="col">
            <label for="code" class="form-label">Send this code to the friend:</label>
            <input v-model="sessionId" type="text" class="form-control" readonly/>
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-primary" :disabled="!sessionId" @click="ev => copy(ev, sessionId)">Copy</button>
          </div>
        </div>
      </form>
      <form>
        <div>
          <div class="row g-2 align-items-end">
            <div class="col">
              <label for="code" class="form-label">Or send this link to the friend:</label>
              <input v-model="link" type="text" class="form-control" readonly/>
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-primary" :disabled="!link" @click="ev => copy(ev, link)">Copy</button>
            </div>
          </div>
        </div>
      </form>
      <button type="button" class="btn btn-primary" :disabled="!canStart" @click="controller.startGame">Start game</button>
      <RouterLink to="/" class="btn btn-primary">&lt; Back</RouterLink>
      <UserName/>
    </div>
  </div>
  <Connector ref="connector"/>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import type { GameData } from "gamexn";
import { SessionController } from "gamexn/client/session-controller";
import Games from "../../games.json";
import Connector from "../components/Connector.vue";
import UserName from "../components/UserName.vue";

const canStart = ref(false);
const connector = ref<InstanceType<typeof Connector> | undefined>();
const controller = new SessionController();
const gameData = Games[0] as GameData;
const router = useRouter();
const sessionId = ref<string>();

const link = computed(() => {
  if (!sessionId.value)
    return undefined;

  return location.origin + router.resolve({
    name: "invited",
    params: { sessionId: sessionId.value }
  }).href;
});

onMounted(() => {
  connector.value?.show();

  controller
      .on("gameStarted", () => router.push({
        name: "game",
        params: { authToken: controller.authToken, handle: controller.handle, sessionId: controller.sessionId },
        replace: true
      }))
      .on("sessionCreated", s => sessionId.value = s)
      .on("socketConnected", () => connector.value?.hide())
      .on("socketDisconnected", () => {
        sessionId.value = undefined;
        setCanStart();
        connector.value?.show();
      })
      .on("socketReconnected", () => controller.createSession(gameData))
      .on("userAdded", setCanStart)
      .on("userRemoved", setCanStart);

  controller.connectAsync().then(() => controller.createSession(gameData)).catch(() => {
  });
});

onBeforeUnmount(() => controller.disconnect());

function copy(ev: Event, value?: string) {
  if (!value)
    return;

  const el = ev.target as HTMLButtonElement;
  navigator.clipboard.writeText(value);

  el.disabled = true;
  el.textContent = "✓";

  setTimeout(() => {
    el.disabled = false;
    el.textContent = "Copy";
  }, 1000);
}

function setCanStart() {
  canStart.value = controller.players.size >= (gameData.minParticipants || 1) + 1;

  if (canStart.value)
    controller.startGame();
}

</script>
